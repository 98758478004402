import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { catchError, shareReplay, skipWhile, tap } from 'rxjs/operators';

@Injectable()
export abstract class BaseStoreService<Request, Response> implements OnDestroy {
    protected __rest$!: Observable<Readonly<Response>>;
    protected _subscription: Subscription = new Subscription();
    protected readonly _errorStream$ = new BehaviorSubject<Error | null>(null);
    protected readonly _loadingStream$ = new BehaviorSubject<boolean>(false);
    protected readonly _restStream$ = new ReplaySubject<Readonly<Response>>(1);
    protected _request: Request | null = null;
    protected _lastResponse!: Readonly<Response>;

    public get isLoading$(): Observable<boolean> {
        return this._loadingStream$.asObservable();
    }

    public get error$(): Observable<Error | null> {
        return this._errorStream$.asObservable();
    }

    protected abstract _shouldLoad(newRequest: Request): boolean;
    protected abstract _getRest(): (request: Request | null) => Observable<Readonly<Response>>;

    public get(): Observable<Response> {
        return this._restStream$.asObservable();
    }

    public load(request: Request, forceLoad = false): Observable<Response> {
        if (forceLoad || this._shouldLoad(request) || !this.__rest$) {
            this._request = request;
            return this._load();
        }

        return this.__rest$;
    }

    public reload(): Observable<Response> {
        return this._load();
    }

    public last(): Observable<Response> {
        return this.__rest$ || EMPTY;
    }

    public ngOnDestroy(): void {
        console.log('destroy base-store service!');
        this._subscription.unsubscribe();
        // this._errorStream$.unsubscribe();
        // this._loadingStream$.unsubscribe();
        // this._restStream$.unsubscribe();
    }

    private _load(): Observable<Response> {
        this._subscription.unsubscribe();

        this._loadingStream$.next(true);
        this._errorStream$.next(null);

        this.__rest$ = this._getRest()(this._request).pipe(shareReplay());
        this._subscription = this.__rest$
            .pipe(
                tap(() => this._errorStream$.next(null)),
                catchError((error) => {
                    this._errorStream$.next(error);
                    this._loadingStream$.next(false);
                    return of(null);
                }),
                skipWhile(() => !!this._errorStream$.value),
            )
            .subscribe((data) => {
                this._lastResponse = data as Readonly<Response>;
                this._restStream$.next(data as Readonly<Response>);
                this._loadingStream$.next(false);
            });

        return this.__rest$;
    }
}
