import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    DestroyRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    inject,
    PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs/operators';
import { EbTabComponent } from '../tab/tab.component';
import { isPlatformBrowser } from '@angular/common';

export interface IActivatedTab<D = void> {
    index: number;
    tab: EbTabComponent<D>;
}

@Component({
    selector: 'eb-tabset',
    templateUrl: './tabset.component.html',
    styleUrls: ['./tabset.component.less'],
})
export class EbTabsetComponent implements AfterContentInit {
    private readonly _platformId = inject(PLATFORM_ID);
    private _destroyRef = inject(DestroyRef);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    @Input() activeTab = 0;
    @Input() contentStyle: any = null;
    @Input() showLoader = true;
    @Output() readonly activated = new EventEmitter<IActivatedTab>();
    @Output() readonly loaded = new EventEmitter<IActivatedTab>();
    @ContentChildren(EbTabComponent) readonly tabs!: QueryList<EbTabComponent>;

    private _isLoading = false;
    get isLoading(): boolean {
        return this._isLoading;
    }

    set isLoading(isLoading: boolean) {
        this._isLoading = isLoading;
    }

    public ngAfterContentInit(): void {
        if (this.activeTab >= this.tabs.length) {
            this.activeTab = 0;
        }

        this.tabs.changes.pipe(startWith(null), takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this.tabs.forEach((tab, i) => {
                if (i === this.activeTab) {
                    this.activate(tab);
                } else if (tab.seoBot) {
                    this._loadData(tab);
                }
            });
        });
    }

    protected activate(tab: EbTabComponent, fromClick?: boolean): void {
        if (tab.active) {
            return;
        }

        if (fromClick) {
            this.isLoading = true;
            if (isPlatformBrowser(this._platformId)) {
                setTimeout(() => {
                    this._activateTab(tab);
                    this._changeDetectorRef.markForCheck();
                });
            } else {
                this._activateTab(tab);
                this._changeDetectorRef.markForCheck();
            }
        } else {
            this._activateTab(tab);
        }
    }

    private _activateTab(tab: EbTabComponent): void {
        this.tabs.forEach((_tab) => _tab.deactivate());
        tab.activate();
        this.activated.next({
            index: this.tabs.toArray().indexOf(tab),
            tab,
        });
    }

    private _loadData(tab: EbTabComponent): void {
        this.loaded.next({
            index: this.tabs.toArray().indexOf(tab),
            tab,
        });
    }

    public reset(): void {
        this.activate(this.tabs.first);
    }
}
